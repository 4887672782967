// Custom colours for the site
$secondary-color: #f2c76e;
$accent-color: #f18fac;
$primary-dark: #331d5d;
$primary-color: #c2b4d6;
$gray: #898b92;
$light-gray: #9e9fa5;
$dusty-blue: #8aa1ce;

// Headings
$heading-font-family: "Work Sans", Helvetica, Arial, sans-serif;
$heading-font-color: #000;
$heading-line-height: 1.2;
$heading-font-weight: 600;

// Body font
$font-family: "IBM Plex Sans", Helvetica Neue, sans-serif;
$font-color: #585757;
$line-height: 1.6;
$font-size: 15px;
$dark-font-color: #121212;

// Code font
$mono-font: "IBM Plex Mono", "Liberation Mono", "Courier New", monospace;
