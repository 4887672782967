/* Code
  =============== */


pre.language-terminal code.language-terminal {
  position: relative;
  padding: 1rem;
  font-family: "IBM Plex Mono","Liberation Mono","Courier New",monospace;
  border: 1px solid #d8d9da;
  box-shadow: 3px 5px 20px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  padding-top: 45px;
  margin: 2rem 0;
  background: white;
  color: #333;

  &::before {
    content: '\2022 \2022 \2022';
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    background: #e1e1e1;
    color: #c2c3c4;
    width: 100%;
    font-size: 2.5rem;
    margin: 0;
    line-height: 0;
    padding: 14px 0;
    text-indent: 4px;
    letter-spacing: -15px;
  }
}

code[class*='language-'],
pre[class*='language-'],
pre code {
  font-family: "IBM Plex Mono","Liberation Mono","Courier New",monospace;
  direction: ltr;
  text-align: left;
  white-space: pre-wrap;
  word-spacing: normal;
  word-break: normal;
  line-height: 1.7;
  font-weight: 400;
  tab-size: 2;
  hyphens: none;
  border-radius: 2px;
  -webkit-font-smoothing: antialiased;
}

/* Code blocks */

pre code {
  display: block;
  overflow: auto;
  border: 0;
  box-shadow: 2px 4px 25px rgba(0, 0, 0, 0.15);
}

// Keyboard input
kbd {
  background-color: #f7f7f7;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 0 2px white inset;
  color: #333;
  display: inline-block;
  font-family: Helvetica, Arial, sans-serif;
  line-height: 1.4;
  margin: 0 0.1em;
  padding: 0.1em 0.6em;
  text-shadow: 0 1px 0 white;
  font-size: 14px;
}

/* Inline code */

:not(pre) > code[class*='language-'] {
  padding: 3px 5px;
  color: #111111;
}

:not(pre) > code {
  padding: 3px 5px;
  border: none;
  font-family: "IBM Plex Mono","Liberation Mono","Courier New",monospace;
  font-weight: 400;
  color: #111111;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.06);
}

code[class*='language-css'],
code[class*='language-scss'],
code[class*='language-less'],
code[class*='language-sass'] {
  color: #cc99cc;
}

.filename {
  background: #111;
  padding: 0.5rem 1rem 0.75rem;
  font-size: 1rem;
  color: white;
  font-weight: bold;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
    margin-bottom: 0!important;
}
